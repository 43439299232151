<template>
<div>
  <v-row class=" mt-2 mb-6 ml-3">
    <v-col cols="12" lg="9">
      <h1 class="text-h5 secondary--font font-weight-large">Preferences</h1>
    </v-col>
  </v-row>
  <v-container
    fluid
    class="ml-5"
    :class="`profile-settings-wrapper ${
      $vuetify.breakpoint.smAndUp ? '' : 'pl-5'
    }`"
  >
    <v-row class="mt-1">
      <v-col cols="12" sm="6" md="6" class="pa-0">
        <h3
          class="subtitle-1 secondary--font font-weight-large identity-heading"
        >
          Default Account
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <div class="mt-0 grey--text text--darken-2 mb-3">
        Choose the default account at launch when you login to Maropost Cloud.
      </div>
    </v-row>

    <v-form
      ref="form"
      id="user-preferences-form"
      @submit.prevent="savePreferences"
      v-model="isFormValid"
    >
      <v-row>
        <v-col
          cols="11"
          sm="6"
          md="6"
          :class="$vuetify.breakpoint.smAndUp ? 'ml-0 pl-0 pr-9' : 'ml-0 pl-0'"
        >
          <v-autocomplete
            id="default-account"
            v-model="userProfile.default_account_id"
            outlined
            label="Select Default Account"
            item-text="name"
            item-value="id"
            item-color="primary"
            clearable
            :search-input.sync="searchAccount"
            :loading="accountsLoading"
            :items="accountsList"
            no-data-text="No accounts available"
            @change="setEnableSaveProfile(true)"
          >
            <template #append-item>
              <v-responsive class="overflow-y-auto" max-height="400">
                <span v-intersect="loadNextPage" />
              </v-responsive>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="1"
          :class="$vuetify.breakpoint.smAndUp ? 'mt-3 ml-n9' : 'mt-3 ml-n4'"
        >
          <v-tooltip
            bottom
            :color="$appConfig.tooltip.colors.dark"
            max-width="20%"
          >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on"> mdi-information-outline </v-icon>
            </template>
            <span attach class="caption">
              Every time you login to Maropost, you will be directed to the
              Default Account you specify here. If you leave this field blank,
              when you login back to Maropost you will be directed to the
              account you accessed last before ending your session.
            </span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row class="mt-2">
        <h3
          class="subtitle-1 secondary--font font-weight-large identity-heading"
        >
          Timezone
        </h3>
      </v-row>
      <v-row>
        <div class="mt-0 grey--text text--darken-2">
          Choose timezone to display date and time accordingly.
        </div>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="6"
          :class="$vuetify.breakpoint.smAndUp ? 'ml-0 pl-0 pr-9' : 'ml-0 pl-0'"
        >
          <v-autocomplete
            id="default-timezone"
            v-model="userProfile.time_zone"
            label="Select Timezone"
            item-color="primary"
            :loading="timezoneLoading"
            :items="timezoneLists"
            item-text="name"
            item-value="value"
            no-data-text="No timezone available"
            @change="setEnableSaveProfile(true)"
            @blur="onBlur"
            outlined
            attach
          />
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <h3
          class="subtitle-1 secondary--font font-weight-large identity-heading"
        >
          Notification Preferences
        </h3>
      </v-row>
      <v-row>
        <div class="mt-0 grey--text text--darken-2">
          Choose what and where you want to be notified about when various
          events occur.
        </div>
      </v-row>
      <v-row>
        <v-col class="ml-0 pl-0 mt-n4">
          <v-switch
            id="enable-notifications"
            v-track="'enable-notifications'"
            v-model="userProfile.enable_notifications"
            color="primary"
            class="preferences-bottom-margin d-inline-block"
            @change="setEnableSaveProfile(true)"
          />
          <span class="el-relative">In-app Notifications</span>
          <div class="ml-11 mt-n2 pt-0 preferences-secondary-label">
            Receive notification alerts about your ongoing tasks in the
            application.
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-6">
        <h3
          class="subtitle-1 secondary--font font-weight-large identity-heading"
        >
          Subscription Preferences
        </h3>
      </v-row>
      <v-row>
        <div class="mt-0 grey--text text--darken-2">
          Sign up to receive emails about various updates to our platform.
        </div>
      </v-row>
      <v-row>
        <v-col class="ml-0 pl-0 mt-n4">
          <v-switch
            id="product-newsletters-switch"
            v-track="'product-newsletters-switch'"
            v-model="userProfile.subscribe_to_newsletter"
            color="primary"
            class="preferences-bottom-margin d-inline-block"
            @change="setEnableSaveProfile(true)"
          />
          <span class="el-relative">Product Newsletters</span>
          <div class="ml-11 mt-n2 pt-0 preferences-secondary-label">
            Monthly newsletters providing a preview of what features are being
            released in the upcoming month, updates on Maropost news and
            marketing trends, as well as invitations to upcoming events such as
            webinars.
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ml-0 pl-0 mt-n6">
          <v-switch
            id="status-updates-switch"
            v-track="'status-updates-switch'"
            v-model="userProfile.subscribe_to_status_update"
            color="primary"
            class="preferences-bottom-margin d-inline-block"
            @change="setEnableSaveProfile(true)"
          />
          <span class="el-relative">Status Updates</span>
          <div class="ml-11 mt-n2 pt-0 preferences-secondary-label">
            Receive notifications to learn about the technical details and
            impact of planned or unplanned service outages.
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ml-0 pl-0 mt-n6">
          <v-switch
            id="release-notes-switch"
            v-track="'release-notes-switch'"
            v-model="userProfile.subscribe_to_release_notes"
            color="primary"
            class="preferences-bottom-margin d-inline-block"
            @change="setEnableSaveProfile(true)"
          />
          <span class="el-relative">Release Notes</span>
          <div class="ml-11 mt-n2 pt-0 preferences-secondary-label">
            Get monthly emails on what has been released this month.
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-6">
        <h3
          class="subtitle-1 secondary--font font-weight-large identity-heading"
        >
          Maropost Labs
        </h3>
      </v-row>
      <v-row>
        <div class="mt-0 grey--text text--darken-2">
          Participate in product research to share your thoughts and opinions to
          help shape the future of our product.
        </div>
      </v-row>
      <v-row>
        <v-col class="ml-0 pl-0 mt-n4">
          <v-switch
            id="research-panel-switch"
            v-track="'research-panel-switch'"
            v-model="userProfile.subscribe_to_research_panel"
            color="primary"
            class="preferences-bottom-margin d-inline-block"
            @change="setEnableSaveProfile(true)"
          />
          <span class="el-relative">Research Panel</span>
          <div class="ml-11 mt-n2 pt-0 preferences-secondary-label">
            As a research panel member, you will receive the user participation
            emails (including surveys, feedback sessions, user testing, and so
            on) for new product initiatives and updates.
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ml-0 pl-0 mt-n6">
          <v-switch
            id="product-advisory-council-switch"
            v-track="'product-advisory-council-switch'"
            v-model="userProfile.subscribe_to_pac"
            color="primary"
            class="preferences-bottom-margin d-inline-block"
            @change="setEnableSaveProfile(true)"
          />
          <span class="el-relative">Product Advisory Council (PAC)</span>
          <div class="ml-11 mt-n2 pt-0 preferences-secondary-label">
            As a PAC member, you will have the opportunity to be involved in PAC
            meetings where you can get updates and provide feedback on the
            latest improvements and product initiatives.
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-6">
        <v-btn
          type="submit"
          color="dark-black"
          :disabled="disableForm"
          :dark="enableSaveProfile"
          :loading="updating || loading"
          class="font-weight-bold white--text"
          v-track="'save-profile-information-btn'"
        >
          SAVE CHANGES
        </v-btn>
      </v-row>
    </v-form>
  </v-container>
</div>
</template>
<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import { listTimezones } from "@/services";
import { formatDate, isEmpty, pick, sanitizePayload } from "@/utils";
import GainsightMixin from "@/mixins/Gainsight.mixin";
import { userAccounts, updateUserProfile } from "@/services/users";
import { DEBOUNCE_MILLIS, DEFAULT_PER_PAGE_RECORDS, KEYCLOAK_USER_DETAILS } from "@/constants/app";

export default {
  name: "ProfilePreferences",
  /**
  |--------------------------------------------------
  | Mixins
  |--------------------------------------------------
  */
  mixins: [GainsightMixin],
  /**
  |--------------------------------------------------
  | Data Properties
  |--------------------------------------------------
  */
  data() {
    return {
      userProfile: {
        enable_notifications: false,
        default_account_id: "",
        subscribe_to_newsletter: false,
        subscribe_to_pac: false,
        subscribe_to_release_notes: false,
        subscribe_to_research_panel: false,
        subscribe_to_status_update: false,
        time_zone: ""
      },
      enableSaveProfile: false,
      isFormValid: false,
      loading: false,
      updating: false,
      accountsLoading: false,
      accountsMeta: {
        page: 1,
        per_page: DEFAULT_PER_PAGE_RECORDS,
        name_cont: "",
      },
      userAccountsList: [],
      searchAccount: "",
      debounceTimer: null,
      timezoneLists: [],
      timezoneLoading: false,
      tempTZ: '',
    };
  },
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      profile: "auth/userProfile",
    }),
    disableForm() {
      return !this.enableSaveProfile || !this.isFormValid;
    },
    accountsList() {
      if (!isEmpty(this.userAccountsList)) return this.userAccountsList;
      else return [];
    },
    /**
     * Do user have more accounts ?
     */
    hasMoreAccounts() {
      if (!this.accountsMeta?.total) return true;

      return (
        this.accountsMeta?.page * this.accountsMeta?.per_page <
        this.accountsMeta?.total
      );
    },
    /**
     * aptrinsicData
     * @description Event data to be send to aptrinsicData
     */
    aptrinsicData() {
      return {
        Date: formatDate("YYYY-MM-DD HH:mm:ss ZZ"),
        Newsletters: this.userProfile.subscribe_to_newsletter ? 1 : 0,
        "Research Panel": this.userProfile.subscribe_to_research_panel ? 1 : 0,
        Notifications: this.userProfile.enable_notifications ? 1 : 0,
        Updates: this.userProfile.subscribe_to_status_update ? 1 : 0,
        PAC: this.userProfile.subscribe_to_pac ? 1 : 0,
        "Release Notes": this.userProfile.subscribe_to_release_notes ? 1 : 0,
      };
    },
    defaultAccountDetails() {
      const index = this.findDefaultAccount();
      if (index > -1) return this.userAccountsList[index];
      return null;
    },
  },
  /**
  |--------------------------------------------------
  | Watching properties
  |--------------------------------------------------
  */
  watch: {
    searchAccount(val) {
      if (!val) return;
      this.debounceAccountSearch();
    },
    'userProfile.time_zone'(newVal) {
      // Update previousValue if new value is not empty
      if (newVal !== null && newVal !== '') {
        this.tempTZ = newVal;
      }
    }
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapMutations({
      updateUserDetails: "auth/UPDATE_CURRENT_USER_DETAILS",
      setUserDetails: "auth/SET_USER_PROFILE_DETAILS",
    }),
    ...mapActions({
      setSnackbar: "ui/setSnackbar",
      setHideSnackbar: "ui/setHideSnackbar",
      setCachedAccounts: "unified/setCachedAccounts",
    }),
    setEnableSaveProfile(value = true) {
      this.enableSaveProfile = value;
    },
    /**
     * fetchProfileInfo
     * @description Fetch user preferences
     */
    async fetchProfileInfo() {
      try {
        this.setHideSnackbar(true);
        this.loading = true;
          
        let result = await this.$keycloak.loadUserProfile();
        let data = result?.attributes?.preferences?.length === 1 
          ? JSON.parse(result.attributes.preferences[0]) 
          : {};

        this.userProfile = {
          ...data,
          default_account_id: Number(data.default_account_id),
        };
      } finally {
        this.setHideSnackbar(false);
        this.loading = false;
      }
    },
    /**
     * getTimezoneList
     * @description Fetch list of timezones
     */
     async getTimezoneList() {
      try {
        this.timezoneLoading = true;
        const timezoneLists = await listTimezones();
        this.timezoneLists = timezoneLists.data.data;
      } finally {
        this.timezoneLoading = false;
      }
    },
    sendAptrinsic() {
      this.triggerEvent("track", "Profile Preference", this.aptrinsicData);
    },
    /**
     * savePreferences
     * @description Updates user preferences
     */
    async savePreferences() {
      try {
        this.updating = true;
        let default_account_id = this.userProfile.default_account_id
          ? String(this.userProfile.default_account_id)
          : "";

         const payload = {
            ...this.profile,
            attributes: {
              ...this.profile.attributes,
              preferences: [
                JSON.stringify({ ...this.userProfile, default_account_id })
              ],
            },
          };
          
          sanitizePayload(payload)

          // Call Keycloak API to update the user profile
          await updateUserProfile(payload);

          // Extract and update required user details
          const keycloakUser = pick(payload, KEYCLOAK_USER_DETAILS);
          this.updateUserDetails(keycloakUser);
          this.setUserDetails(keycloakUser);

        this.sendAptrinsic();

        this.setSnackbar({
          value: true,
          message: "Preferences updated successfully",
          type: this.$appConfig.snackbar.snackbarTypes.success,
        });
      } finally {
        this.updating = false;
        this.setEnableSaveProfile(false);
      }
    },
    /**
     * Debounce search accounts
     */
    debounceAccountSearch() {
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }

      this.debounceTimer = setTimeout(() => {
        const params = {
          name_cont: this.searchAccount,
          page: 1,
          per_page: DEFAULT_PER_PAGE_RECORDS,
        };

        if (this.defaultAccountDetails?.name !== this.searchAccount) {
          this.accountsMeta.name_cont = this.searchAccount;
        }
        this.fetchAccounts(params);
        this.debounceTimer = null;
      }, DEBOUNCE_MILLIS);
    },
    /**
     * fetchAccounts
     * @description Fetches user accounts
     */
    async fetchAccounts(params = this.accountsMeta) {
      try {
        params = { ...params };
        if (params.total) delete params.total;

        this.accountsLoading = true;
        const { data } = await userAccounts(params);

        if (!this.searchAccount && this.hasMoreAccounts) {
          this.accountsMeta = { ...data.meta };
        }

        this.userAccountsList = this.mergeAccounts(data);
        this.setCachedAccounts(this.userAccountsList);
      } finally {
        this.accountsLoading = false;
      }
    },
    /**
     * getDefaultAccount
     * @description Fetch details of default account
     */
    async getDefaultAccount(accountId) {
      if (!accountId) return;

      try {
        this.accountsLoading = true;
        const params = { id_eq: accountId };

        const { data } = await userAccounts(params);
        if (!isEmpty(data?.data)) this.userAccountsList.push(data?.data?.[0]);
      } finally {
        this.accountsLoading = false;
      }
    },
    findDefaultAccount() {
      return this.accountsList.findIndex(
        ({ id }) => id == this.userProfile.default_account_id
      );
    },
    /**
     * Detemine and prefetch default account if it is not present in the accounts list
     */
    async preFetchDefaultAccount() {
      if (!isEmpty(this.accountsList) && this.userProfile.default_account_id) {
        const index = this.findDefaultAccount();
        if (index < 0) {
          this.getDefaultAccount(this.userProfile.default_account_id);
        }
      }
    },

    /**
     * Merges accounts
     */
    mergeAccounts(data = {}) {
      return [...this.userAccountsList, ...(data?.data ?? [])];
    },
    /**
     * Loads next page accounts records
     */
    loadNextPage() {
      if (this.accountsLoading) return;
      if (this.hasMoreAccounts && this.accountsMeta?.total > 0) {
        this.accountsMeta.page++;
        this.fetchAccounts();
      }
    },
    onBlur() {
      if (this.userProfile.time_zone === null || this.userProfile.time_zone === '')
        this.userProfile.time_zone = this.tempTZ;
    }
  },

  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  async mounted() {
    await Promise.allSettled([
      this.fetchProfileInfo(),
      this.fetchAccounts()
    ]);
    this.preFetchDefaultAccount();
    this.getTimezoneList();
  },
};
</script>
<style lang="scss">
.profile-settings-wrapper {
  .preferences-bottom-margin .v-input__control .v-input__slot {
    margin-bottom: 0 !important;
  }
  .v-input--selection-controls.v-input .v-label {
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 16px;
  }
  .preferences-secondary-label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6) !important;
  }
  .el-relative {
    position: relative;
    top: -3px;
  }
}
</style>
